import styled from "styled-components";
import { Container } from "../../style/Grid";

export const ArticleStyle = styled(Container)`
    padding: 16px 20px 24px 20px;
    text-decoration: none;
    box-shadow: 0px 4px 60px 0px #0000001A;
    width: 300px;

    &:hover {
        h5 {
            color: rgb(89, 90, 91);
        }
    }

    p {
        text-decoration: underline;
    }

    > div > div {
        > svg {
            width: 60px;
            height: 60px;
        }

        > .boiler-icon {
            width: 46px;
            height: "auto";
            margin-bottom: 2px;
        }

        > * {
            margin-right: 0;
        }
    }

    @media (max-width: 575px) {
        width: 100%;
        padding: 24px 16px;

        > div > div {
            > svg {
                width: 40px;
                height: 40px;
            }

            > .boiler-icon {
                margin-left: 4px;
                width: 32px;
                height: "auto";
                margin-right: 18px;
            }

            > * {
                margin-right: 16px;
            }
        }
    }
`;

ArticleStyle.displayName = "ArticleStyle";
