import styled from "styled-components";

import Wrapper from "../Grid/Wrapper";
import themeGet from "@styled-system/theme-get";

const GoToMndBannerStyle = styled(Wrapper)`
	background:  url("${require("../../images/go-to-mnd-banner.jpg").default}") no-repeat top center, ${themeGet("colors.green.dark")};
	color: ${themeGet("colors.white.100")};
  background-size: ${props => (props.type === "HP" ? "cover" : "auto")};

	@media screen and (max-width: 599px) {
		background: ${themeGet("colors.green.dark")};
	}

	.goToMnd__header {
		@media screen and (max-width: 440px) {
			background-position: top 28px right;
		}
	}

	.goToMnd__boxes {
		& > div {
			max-width: none;
			min-width: 1px;

			p {
				img {
					margin-bottom: 0;
					max-width: 28px;
					vertical-align: middle;
					position: relative;
					top: -9px;
				}
			}

			a {
				padding: 12px 35px;
				font-size: 18px;
				line-height: 1.4;

				max-width: 180px;
				margin: 0 auto;

				height: auto;
			}

			a.newHP, button.newHP {
				max-width: 100%;
        text-align: center;
        text-decoration: underline;
				width: 100%;
				font-size: 14px;
				display: block;
				padding: 0 !important;
				margin: 20px 0;
			}

		}



		@media screen and (max-width: 1000px) {
			width: 100%;
		}

		@media screen and (max-width: 700px) {
			flex-direction: column;
			align-items: center;
			.spacer {
				display: none;
			}

			&--HP {
				& > div {
					flex-direction: column;

					p {
						line-height: 1.3 !important;
						font-size: 14px !important;

						&:first-of-type {
							font-size: 30px !important;
							margin-bottom: 10px;
						}

						&:last-of-type {
							font-size: 12px !important;
						}
					}
				}
			}

			& > div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 32px 32px;
				width: 75%;
				margin: 0;

				p {
					flex: 0 1 calc(50% - 9px);
					font-size: 30px;
					line-height: 40px;
					margin-bottom: 0;

					img {
						top: -9px;
						max-width: 30px;
					}
				}

				&:first-child {
					margin-bottom: 8px;
				}

				&:last-child {
					margin-top: 8px;
				}
			}
		}

		@media screen and (max-width: 599px) {
			padding-bottom: 34px;

			& > div {
				padding: 32px 16px;
				width: 96%;

				p {
					font-size: 26px;
					line-height: 36px;

					img {
						top: -6px;
						max-width: 20px;
					}
				}

				a {
					padding: 6px 28px;
					font-size: 16px;
					line-height: 25px;

					margin: 0;
				}
			}
		}

		@media screen and (max-width: 400px) {
			& > div {
				a {
					flex: 0 1 calc(50% - 9px);
					padding: 6px 22px;
				}
			}
		}
	}


`;

GoToMndBannerStyle.displayName = "GoToMndBannerStyle";

GoToMndBannerStyle.defaultProps = {
  width: "100%",
};

export default GoToMndBannerStyle;
