import React from 'react';
import PropTypes from "prop-types";
import { Heading } from "../../style/Heading";
import { Graph, Savings, HeatPump, LinkSquare, Bulb, Fridge, SolarPanel, Boiler } from '../../style/icons/';
import { Link } from "gatsby";
import { Text } from "../../style/Text";
import { ArticleStyle } from "./ArticleStyle";
import { Flex } from "../../style/Grid";

const icons = {
  graph: <Graph />,
  savings: <Savings />,
  heatPump: <HeatPump />,
  bulb: <Bulb />,
  fridge: <Fridge />,
  solarPanel: <SolarPanel />,
  boiler: <Boiler />,
};

export const Article = ({ title, href, icon, background, linkText }) => {
    return <ArticleStyle bg={background} as={Link} to={href}>
        <Flex flexDirection={["row", "column", null]} flex={1}>
          <div>{icons[icon]}</div>
          <Heading
            as={"h5"}
            color="rgb(43, 110, 51)"
            fontSize={20}
            lineHeight={1.4}
            fontWeight={700}
            mt={[0, '8px']}
            mb={['18px', '24px']}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Flex>
        <Flex alignItems={'center'} width={'100%'} justifyContent={["end", "start"]}>
          <LinkSquare />
          <Text
            fontSize={14}
            color="rgb(43, 110, 51)"
            textDecoration="underscore"
            lineHeight={1.4}
            fontWeight={600}
            marginLeft={2}
            dangerouslySetInnerHTML={{ __html: linkText }}
          />
        </Flex>
    </ArticleStyle>
};

Article.defaultProps = {
  background: 'white.100',
  icon: 'graph',
}

Article.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.string,
  linkText: PropTypes.string,
  background: PropTypes.string,
};
