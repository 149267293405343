import styled from "styled-components";
import {
  space,
  color,
  layout,
  typography,
  flexbox,
  border,
} from "styled-system";
import propTypes from "@styled-system/prop-types";

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;

  ${space}
  ${color}
  ${layout}
  ${typography}
  ${flexbox}
  ${border}
`;

List.displayName = `List`;

List.defaultProps = {};

List.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
  ...propTypes.border,
};

export { List };
