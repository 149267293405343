import styled from "styled-components";
import Wrapper from "../style/Grid/Wrapper";

export const WhyMndWrapper = styled(Wrapper)`
  padding-top: 40px;
  padding-bottom: 40px;

  @media (min-width: 767.98px) {
    padding-top: 56px;
    padding-bottom: 56px;
  }
`;

WhyMndWrapper.displayName = "WhyMndWrapper";
