import React from "react";
import { Box, Container, Flex } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import Breadcrumbs from "../breadcrumbs";
import { Text } from "../../style/Text";
import GoToMndBannerStyle from "../../style/Banners/GoToMndBannerStyle";
import { Button } from "../../style/Button";
import config from "../../configs";
import { OF_FIRST_STEP_SLUG } from "../../constants";

const GoToMndBanner = ({ title, subtitle, name }) => {
  return (
    <GoToMndBannerStyle>
      <Container>
        <Breadcrumbs name={name} />
        <Flex
          width={1}
          maxWidth={952}
          minHeight={178}
          background={`url("${require("../../images/hp-stamp-white.svg").default}") no-repeat top right`}
          backgroundSize={[90, 135, 145, 160, 200]}
          mx={"auto"}
          mt={0}
          mb={[24, 54]}
          pt={"8px"}
          flexDirection="column"
          flexGrow="1"
          className={"goToMnd__header"}
        >
          <Heading
            maxWidth={"70%"}
            mb={"8px"}
            fontWeight="700"
            lineHeight={"1.2"}
            color="white.100"
            fontSize={["28px", "42px", null, null]}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Heading
            as="h3"
            fontSize={["20px", "22px"]}
            fontWeight="700"
            lineHeight={"1.2"}
            color="white.100"
            mt={0}
            mb="20px"
            pr={[99, 0]}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
          <Text fontSize={["15px", "18px"]} lineHeight={1.3} mb={"8px"}>
            Krok za&nbsp;krokem vás provedeme vyplněním dokumentů, i&nbsp;s&nbsp;jejich podpisem
          </Text>
          <Text fontSize={["15px", "18px"]} lineHeight={1.3} mb={"8px"}>
            Celý proces probíhá ve&nbsp;vašem počítači nebo telefonu. Žádné papíry ani chození na&nbsp;pobočky
          </Text>
        </Flex>

        <Flex width={"70%"} mx={"auto"} justifyContent={"center"} pb={44} className={"goToMnd__boxes"}>
          <Box width={"49%"} maxWidth={334} minWidth={"auto"} mr={"12px"} py={30} px={20} textAlign={"center"} backgroundColor={"white.100"}>
            <Text pb={"10px"} mb={14} fontSize={36} lineHeight={1.4} fontWeight={700} color={"green.dark"}>
              <img src={require("../../images/HP_dark.gif").default} alt="" />
              <span>&nbsp;Plyn</span>
            </Text>
            <Button as={"a"} variant={"primary"} href={`${config.gofu}plyn/${OF_FIRST_STEP_SLUG}?product=PZR_FIX`}>
              Přejít k&nbsp;MND
            </Button>
          </Box>
          <Box width={"49%"} maxWidth={334} minWidth={"auto"} ml={"12px"} py={30} px={20} textAlign={"center"} backgroundColor={"white.100"}>
            <Text pb={"10px"} mb={14} fontSize={36} lineHeight={1.4} fontWeight={700} color={"green.dark"}>
              <img src={require("../../images/HP_el_dark.gif").default} alt="" />
              <span>&nbsp;Elektřina</span>
            </Text>
            <Button as={"a"} variant={"primary"} href={`${config.gofu}elektrina/${OF_FIRST_STEP_SLUG}?product=PROUD_FIX`}>
              Přejít k&nbsp;MND
            </Button>
          </Box>
        </Flex>

        {/*<Flex width={["100%", null, "auto", null]} justifyContent="center" alignSelf="flex-end" flexGrow="1" mt={[0, null, "10px", null]}>
          {image && <Image display={imageMobile && ["none", "block"]} src={image} alt={title} width={["178px", null, "auto", null]} />}
          {imageMobile && <Image display={["block", "none"]} src={imageMobile} alt={title} width={[1, null, "auto", null]} maxWidth={"370px"} />}
        </Flex>*/}
      </Container>
    </GoToMndBannerStyle>
  );
};

export default GoToMndBanner;
