import { useStaticQuery, graphql } from "gatsby";

const useGiveEnergy = () => {
  const { mnd } = useStaticQuery(
    graphql`
    query useGiveEnergy {
      mnd {
        giveEnergy {
          data {
            attributes {
              GiveEnergy {
                title
                description
                giveButtonTitle,
                giveButtonLink,
                activeButtonTitle,
                activeButtonLink
              }
            }
          }
        }
      }
    }
    `
  );

  return mnd.giveEnergy.data.attributes.GiveEnergy;
};

export { useGiveEnergy };
