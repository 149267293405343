import styled from "styled-components";
import { space, color, layout, typography, flexbox, border } from "styled-system";
import propTypes from "@styled-system/prop-types";

const ListItemHp = styled.li`
  padding-left: 20px;
  padding-right: 0;
  position: relative;
  display: inline-block;

  ${space}
  ${color}
  ${layout}
  ${typography}
  ${flexbox}
  ${border}
  
  &:before {
    content: "";
    position: absolute;
    top: 12px;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: ${props => (props.type === "small" ? "12px" : "22px")};
    height: ${props => (props.type === "small" ? "12px" : "22px")};
    background: url("../../images/checkIcon.svg") no-repeat center / contain;
  }

  &.more {
    text-align: left;
  }
`;

ListItemHp.displayName = `ListItem`;

ListItemHp.defaultProps = {
  fontWeight: 400,
  color: "exploratoryGreen",
  fontSize: "14px",
};

ListItemHp.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
  ...propTypes.border,
};

export { ListItemHp };
