import React, { Fragment } from "react";
import { Link } from "gatsby";
import { Button } from "../../style/Button";
import Maintenance from "../../components/Maintenance";
import { Hp } from "../../components/CtaModul";
import { MarkupToHtml } from "../../helpers/parse";

const FixedProductsBanner = ({ title, text, offers, textBottom, bottomLinkList, buttonTitle, buttonLink, fixedProductsBanner }) =>
{
  const showBannerButton = !!buttonTitle && !!buttonLink;

  const BASE_CONTENT_URL = process.env.GATSBY_MEDIA_LIBRARY_URL;
  const bannerLargeImageURL = fixedProductsBanner.largeImage.data.attributes.url;
  const bannerSmallImageURL = fixedProductsBanner.smallImage.data.attributes.url;

  return (
    <Fragment>
      <div className={"relative bg-resource-green-100 pb-10"}>
        <div className={"relative bg-exploratory-green-600 bg-left-bottom md:bg-bottom bg-no-repeat bg-cover md:bg-auto hp-image-container"}>
          <div className={"hidden sm:block absolute top-0 left-0 right-0 bottom-0 h-full mb-0"}>
            <img
              src={`${BASE_CONTENT_URL}${bannerLargeImageURL}`}
              className={"w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover mb-0"}
              alt={"Získejte jistotu dodávky od MND"}
            />
          </div>
          <div className={"block sm:hidden absolute top-0 left-0 right-0 bottom-0 h-full mb-0"}>
            <img
              src={`${BASE_CONTENT_URL}${bannerSmallImageURL}`}
              className={"h-full w-full object-cover mobile-banner"}
              alt={"Získejte jistotu dodávky od MND"}
            />
          </div>
          <div className={"relative mx-auto max-w-screen-lg"}>
            <div className={"relative mw-full flex flex-col md:relative px-4 pb-9 min-h-[430px] max-w-[732px] hidden sm:block"}>
              <div className={"absolute top-70px"}>
                <h1 className={"text-H1 text-[38px] leading-tight mb-2 text-exploratory-green-400"}>
                  <MarkupToHtml>
                    {title}
                  </MarkupToHtml>
                </h1>
                <p className={"text-H4 mb-0 text-exploratory-green-400 font-bold"}>
                  <MarkupToHtml>
                    {text}
                  </MarkupToHtml>
                </p>
                {showBannerButton ? (
                  <Button flex={["0 1 206px"]} maxWidth={"206px"} variant={"greenDark"} as={Link} to={buttonLink} mt={3} ml={[1]} mr={[1]}>
                    {buttonTitle}
                  </Button>
                ) : null}
              </div>
            </div>
            <div className={"relative mw-full flex flex-col px-4 pb-9 min-h-[480px] sm:hidden -bottom-180px"}>
              <div className={"absolute block  bottom-0 left-0 right-0 w-full bg-exploratory-green-gradient px-5 pt-6 pb-100px"}>
                <h1 className={"mb-2 text-white mt-auto fs-7 font-bold"}>
                  <MarkupToHtml>
                    {title}
                  </MarkupToHtml>
                </h1>
                <p className={"text-link mb-0 text-white font-semibold"} >
                  <MarkupToHtml>
                    {text}
                  </MarkupToHtml>
                </p>
                {showBannerButton ? (
                  <Button flex={["0 1 220px"]} maxWidth={"220px"} variant={"primary"} as={Link} to={buttonLink} mt={3} ml={[1]} mr={[1]}>
                    {buttonTitle}
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className={"relative z-50"}>
          <div className={"container mx-auto max-w-screen-lg px-4 -mt-20 relative"}>
            <Maintenance sticky={true} padding={[30, null, 100]} />
            <div className={"flex flex-col sm:flex-row justify-between w-full gap-5"}>
              <Hp commodity={"gas"} offers={offers.filter(o => o.commodity === "gas")} bottomLinkList={bottomLinkList} />
              <Hp commodity={"electricity"} offers={offers.filter(o => o.commodity === "electricity")} bottomLinkList={bottomLinkList} />
            </div>
          </div>

          {textBottom ? (
            <div className={"text-body text-center pt-6 sm:pt-10 px-4 mb-0 m-auto text-exploratory-green-400"} style={{ maxWidth: 720 }}>
              <MarkupToHtml>{textBottom}</MarkupToHtml>
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default FixedProductsBanner;
