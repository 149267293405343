import styled from "styled-components";
import { Flex } from "../../style/Grid";

export const ArticlesWrapper = styled(Flex)`
    gap: 28px;
    max-width: 956px;
    margin: auto;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (max-width: 575px) {
        gap: 16px;
    }
`;

ArticlesWrapper.displayName = "ArticlesWrapper";
