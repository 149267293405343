import React from "react";

import { Flex } from "../style/Grid";
import { Text } from "../style/Text";
import { StyledLink } from "../style/Link";

const Breadcrumbs = ({ name }) => {
  return (
    <Flex py="20px">
      <StyledLink color="white.100" fontSize="13px" to="/">
        Hlavní stránka
      </StyledLink>
      <Text as="span" px="5px" color="white.100" fontSize="13px">
        >
      </Text>
      <Text color="white.100" fontSize="13px" dangerouslySetInnerHTML={{ __html: name }} />
    </Flex>
  );
};

export default Breadcrumbs;
