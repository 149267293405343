import React from "react";

import { useWhyMnd } from "../hooks/useWhyMnd";

import { Container, Flex } from "../style/Grid";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";
import { StyledLink } from "../style/Link";
import { Watch, CheckV2, HandShakeV2, Accessible } from "../style/icons/";
import { theme } from "../style/theme";
import { WhyMndWrapper } from "./WhyMndWrapper";

const WhyMND = ({ title, subTitle, showArticle, bg, ...props }) => {
  const queryData = useWhyMnd();

  return (
    <WhyMndWrapper backgroundColor={bg} {...props}>
      <Container px={["20px", "12px"]}>
        <Flex>
          <Flex flexDirection="column" width={1}>
            <div className="flex-col text-left sm:text-center md:mb-10 mb-5">
              <h2
                className={"text-H3 sm:text-H2 mb-0 bold text-exploratory-green-400"}
                dangerouslySetInnerHTML={{ __html: queryData.title ? queryData.title : title }}
              />

              {subTitle && <Text fontSize="20px" color="exploratoryGreen" pt={2} dangerouslySetInnerHTML={{ __html: subTitle }} />}
            </div>

            {queryData.advantage && (
              <Flex flexWrap={"wrap"}>
                {queryData.advantage.map((item, index) => (
                  <Flex key={index} minWidth={["100%", "50%", null, "25%"]} flexBasis={["100%", "50%", null, "25%"]} mt={["16px", "40px", null, 0]}>
                    <Flex>
                      {item.icon === "clock" && <Watch bg={bg === "white.100" ? "#E6E6E7" : "white"} />}
                      {item.icon === "checkmark" && <CheckV2 bg={bg === "white.100" ? "#E6E6E7" : "white"} />}
                      {item.icon === "handshake" && <HandShakeV2 bg={bg === "white.100" ? "#E6E6E7" : "white"} />}
                      {item.icon === "phone" && <Accessible bg={bg === "white.100" ? "#E6E6E7" : "white"} />}
                    </Flex>
                    <Flex flexDirection="column" pl="14px" width="100%">
                      <Heading
                        as="h3"
                        fontSize={["22px", "22px"]}
                        fontWeight="700"
                        color="exploratoryGreen"
                        mb="10px"
                        display={"flex"}
                        alignItems={"center"}
                        height={"50px"}
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                      <Text fontSize="15px" color="exploratoryGreen" lineHeight="1.5" pr="8px" dangerouslySetInnerHTML={{ __html: item.description }} />
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            )}

            {showArticle && (
              <Flex flexDirection="column" alignItems="center" mb="53px" mt={["30px", "40px", null]} width={1}>
                <Text fontSize="20px" fontWeight="700" lineHeight={"28px"} textAlign={"center"} color="exploratoryGreen" maxWidth={520}>
                  Díky tomu jsme již třetím rokem nejdoporučovanějším dodavatelem energií.{" "}
                  <StyledLink color="exploratoryGreen" to="/novinky/mnd-znovu-zakazniky-zvoleny-nejdoporucovanejsim-dodavatelem-energii">
                    Více zde
                  </StyledLink>
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Container>
    </WhyMndWrapper>
  );
};

WhyMND.defaultProps = {
  showArticle: false,
  title: "Proč zvolit MND?",
  subTitle: null,
  bg: theme.colors.resourceGreenLighter,
};

export default WhyMND;
