import { useStaticQuery, graphql } from "gatsby";

const useWhyMnd = () => {
  const { mnd } = useStaticQuery(
    graphql`
      query whyMND {
        mnd {
          advantage {
            data {
              attributes {
                title
                advantage {
                  icon
                  title
                  description
                }
              }
            }
          }
        }
      }
    `
  );
  return mnd?.advantage?.data?.attributes;
};

export { useWhyMnd };
