import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import parse, { domToReact } from "html-react-parser";

import { Button } from "../../style/Button";
import { Wrapper, Flex } from "../../style/Grid";
import { Text } from "../../style/Text";
import { useGiveEnergy } from "../../hooks/useGiveEnergy";

const options = {
  replace: ({ attribs, children, name }) => {
    if (!attribs) {
      return;
    }

    if (name === "a") {
      return (
        <a {...attribs} className={"text-exploratory-green-400 underline hover:no-underline"}>
          {domToReact(children, options)}
        </a>
      );
    }
  },
};

const GiveEnergy = () => {
  const giveEnergy = useGiveEnergy();
  const { title, description, giveButtonTitle, giveButtonLink, activeButtonTitle, activeButtonLink } = giveEnergy || {};

  return (
    <Wrapper height={[400, 400]} mb={[250, 0]} backgroundColor="transparent">
      <Flex flexDirection="column" justifyContent="center" alignItems="flex-start" height="100%" >
        <div className={"text-left"}>
          <h4 className="text-H4 mb-4 uppercase text-exploratory-green-400" dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <Text fontSize="15px" color="exploratoryGreen" lineHeight="1.5" pr="8px" >
          {parse(description || "", options)}
        </Text>
        <Flex justifyContent={["center", "center", "left"]} flexWrap={"wrap"} pt={1} width={"100%"}>
          <Button flex={[" 0 1 200px"]} maxWidth={["200px", "none"]} variant={"greenDark"} as={Link} to={giveButtonLink} mt={3} ml={[1]} mr={[1]}>
            {giveButtonTitle}
          </Button>
          <Button flex={["0 1 200px"]} maxWidth={["200px", "none"]} variant={"greyActive"} as={Link} to={activeButtonLink} mt={3} ml={[1]} mr={[1]}>
            {activeButtonTitle}
          </Button>
        </Flex>
      </Flex >
    </Wrapper>
  );
};

export default GiveEnergy;
