import { useStaticQuery, graphql } from "gatsby";

const useQuestionsRelatedToIncreasePrice = () => {
  const { mnd } = useStaticQuery(
    graphql`
      query useQuestionsRelatedToIncreasePrice {
        mnd {
          questionsRelatedToIncreasePrice {
            data {
              attributes {
                title
                description
                ctaText
                ctaLink
              }
            }
          }
        }
      }
    `
  );
  return mnd?.questionsRelatedToIncreasePrice?.data?.attributes;
};

export { useQuestionsRelatedToIncreasePrice };
