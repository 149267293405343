import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../style/Button";
import { StaticImage } from "gatsby-plugin-image";
import { useQuestionsRelatedToIncreasePrice } from "../../hooks/useQuestionsRelatedToIncreasePrice";
import cx from "classnames";
import { ArticlesWrapper } from '../Article/ArticlesWrapper';
import { Article } from '../Article/Article';

const QuestionsRelatedToIncreasePricesBanner = ({ className, articles }) => {
  const { title, description, ctaText, ctaLink } = useQuestionsRelatedToIncreasePrice();

  const haveArticles = articles && articles.length > 0;

  return (
    <div className={cx("px-5 md:px-0", className)}>
      <div className="pt-12 md:pt-16 pb-10 md:pb-12">
        <div className="flex flex-col md:flex-row items-center md:pb-19 mb-12 md:mb-16 m-auto max-w-3xl">
          <div className={"hidden md:flex flex-col w-full md:w-1/2"}>
            <StaticImage placeholder={"tracedSVG"} src={"../../images/faq-notebook.svg"} alt={""} className="mr-auto ml-0 md:-ml-8" objectFit={"contain"} />
          </div>

          <div className={"flex flex-col w-full md:w-1/2"}>
            <div className={"block md:hidden pb-12 max-w-[281px] m-auto"}>
              <StaticImage placeholder={"tracedSVG"} src={"../../images/faq-notebook.svg"} alt={""} className="mr-auto ml-0 pb-4" objectFit={"contain"} />
            </div>

            <h3 className="text-H4 pb-4 mb-0 uppercase text-exploratory-green-400" dangerouslySetInnerHTML={{ __html: title }} />

            <div className="mb-8 text-body text-exploratory-green-400" dangerouslySetInnerHTML={{ __html: description }} />

            <div className={"flex justify-center sm:justify-start"}>
              <Button as={"a"} href={ctaLink} target={"_blank"} variant={"greenDark"} maxWidth={"200px"} py={3} width={1}>
                {ctaText}
              </Button>
            </div>
          </div>
        </div>
        {haveArticles && <ArticlesWrapper>
          {articles.map((article, index) => <Article key={index} title={article.title} href={article.href} icon={article.icon} linkText={article.linkText} />)}
        </ArticlesWrapper>}
      </div>
    </div>
  );
};

QuestionsRelatedToIncreasePricesBanner.defaultProps = {
  className: "bg-resource-green-100",
};

QuestionsRelatedToIncreasePricesBanner.propTypes = {
  className: PropTypes.string,
  articles: PropTypes.array,
};

export default QuestionsRelatedToIncreasePricesBanner;
