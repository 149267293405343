import React from "react";

import { CONTENT, CONTRACT_SOURCE_ID, PAGE_HP } from "../constants";
import LayoutMain from "../components/layoutMain";
import { Helmet } from "react-helmet";
import config from "../configs";
import { Container, Flex, Wrapper, Box } from "../style/Grid";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";
import { FAQItem } from "../components/FAQ/FAQItem";
import GoToMndBanner from "../components/Banners/GoToMndBanner";
import ContactForm from "../components/contactForm";
import { getCommodityFromHash } from "../helpers";
import OmnisSituationsList from "../components/OmnisSituation/OmnisSituationsList";
import Maintenance from "../components/Maintenance";
import LeadMaintenance from "../components/LeadMaintenance";

export const contentData = {
  title: "Přepis energií – elektřiny i plynu online – MND",
  description:
    "Přepis energií, plynu i elektřiny, vyřídíme online. Proveďte změnu dodavatele, přepis při stěhování, připojení novostavby nebo znovupřipojení odpojeného odběrného místa.",
  ogImage: "/images/prepis-energii/stehovani-share-image.png",
  formScrollToId: "contactForm",
  FAQSection: {
    title: "Přejít k MND je jednoduché",
    FAQs: [
      // {
      //   question: `1.&nbsp;&nbsp;Vypracování kalkulace – zdarma a&nbsp;nezávazně`,
      //   answer: (
      //     <>
      //       <Text>
      //         Připravte si poslední vyúčtování, kde&nbsp;najdete vše potřebné ke&nbsp;zpracování kalkulace, popř. k&nbsp;následnému uzavření smlouvy.
      //         V&nbsp;kalkulačce na&nbsp;našich webových stránkách si nezávazně spočítáte, kolik můžete s&nbsp;MND ušetřit.
      //       </Text>
      //     </>
      //   ),
      // },
      {
        question: `1.&nbsp;&nbsp;Vytvoření nezávazného návrhu smlouvy `,
        answer: (
          <>
            <Text>
              Kliknutím na&nbsp;tlačítko Přejít k&nbsp;MND si vygenerujete nezávazný návrh smlouvy. Jeho pročtení by&nbsp;nemělo zabrat moc času, protože naše
              dokumenty jsou jednoduché a&nbsp;srozumitelné. Případné nejasnosti obratem zodpovíme v&nbsp;chatu nebo na&nbsp;bezplatné telefonní lince{" "}
              <a href={`tel:${CONTENT.phone.href}`}>{CONTENT.phone.formatted}</a> od&nbsp;8 do&nbsp;20 hodin 7 dní v&nbsp;týdnu.
            </Text>
          </>
        ),
      },
      {
        question: `2.&nbsp;&nbsp;Podpis dokumentů a&nbsp;doložení souvisejících dokumentů`,
        answer: (
          <>
            <Text>
              Smluvní dokumenty snadno podepíšete online – prstem na&nbsp;mobilu nebo myší na&nbsp;počítači. Nebo si pro&nbsp;podepsání dokumentů zvolíte jiný
              způsob, který&nbsp;vám bude vyhovovat, např. zaslání kurýrem nebo poštou. Podle situace, ve&nbsp;které&nbsp;se&nbsp;nacházíte, nám potom doplníte
              dokumenty potřebné k&nbsp;dokončení procesu – naše stránky vás krok za&nbsp;krokem navedou a&nbsp;zobrazí, o&nbsp;které&nbsp;se&nbsp;jedná.
              Dokumenty nám vyfotíte a&nbsp;nahrajete přes svůj telefon, nebo jako soubor z&nbsp;počítače. Pokud je&nbsp;nebudete mít k&nbsp;dispozici,
              rozdělanou práci si uložíte, a&nbsp;můžete je&nbsp;doplnit později.
            </Text>
          </>
        ),
      },
      {
        question: `3.&nbsp;&nbsp;Čekání na&nbsp;zahájení odběru od&nbsp;MND`,
        answer: (
          <>
            <Text>
              Obdržené smlouvy a&nbsp;dokumenty u&nbsp;nás zkontrolujeme a&nbsp;sami se&nbsp;Vám ozveme, pokud bude potřeba ještě něco doplnit. V&nbsp;ideálním
              případě ale už vše probíhá v&nbsp;naší režii a&nbsp;přechod k&nbsp;MND zařídíme za&nbsp;vás. Nelze přesně odhadnout, kdy&nbsp;začnete od&nbsp;MND
              odebírat energie, to&nbsp;závisí na&nbsp;smlouvě s&nbsp;vaším současným dodavatelem. Obvyklá doba přechodu je&nbsp;okolo dvou měsíců
              v&nbsp;případě smluv bez závazku. O&nbsp;celém přechodu k&nbsp;MND Vás budeme průběžně informovat prostřednictvím SMS zpráv nebo e-mailu. Svůj
              přechod zároveň můžete sledovat v&nbsp;zákaznickém portále Moje MND, do&nbsp;kterého získáte při&nbsp;sjednání smlouvy přístup.
            </Text>
          </>
        ),
      },
      {
        question: `4.&nbsp;&nbsp;Zahájení odběru`,
        answer: (
          <>
            <Text>
              Před&nbsp;zahájením odběru vás obvykle 1 – 5 dní předem požádáme o&nbsp;nahlášení stavu plynoměru či&nbsp;elektroměru prostřednictvím SMS zprávy.
              Poštou od&nbsp;nás dostanete dopis s&nbsp;rozpisem zálohových plateb pro&nbsp;následujících 12 měsíců.
            </Text>
          </>
        ),
      },
    ],
  },
};

const PrepisEnergii = () => {
  return (
    <LayoutMain type={PAGE_HP}>
      <Helmet
        htmlAttributes={{
          lang: "cs",
        }}
      >
        <title>{contentData.title}</title>
        <meta name="keywords" content="změna dodavatele plynu, změna dodavatele elektřiny, změna dodavatele energie, změna dodavatele energií" />
        <meta name="description" content={contentData.description} />
        <meta name="og:description" content={contentData.description} />
        <meta property="og:image" content={`${config.serverUrl}${contentData.ogImage}`} />
        <script>{`
          var gaAdditionalPageData = JSON.stringify({ type: 'Category', section: 'Gas | Electricity' });
        `}</script>
        <link rel="canonical" href="https://www.mnd.cz/prepis-energii-elektriny-plynu"></link>
      </Helmet>

      <Box position="relative">
        <Maintenance />
        <GoToMndBanner name={"Chci přejít k&nbsp;MND"} title={"Přepište si energie online"} subtitle={"Jednoduše a&nbsp;z&nbsp;pohodlí domova"} />
      </Box>

      <OmnisSituationsList showScrollToContact={false} />

      <Wrapper
        position="relative"
        background={["white.100", `#a7be32 url(${require("../images/contactForm__bg.jpg").default}) no-repeat bottom center`]}
        p={["30px 0", "60px 0 40px"]}
      >
        <Container id="contactForm">
          <LeadMaintenance />
          <Flex flexDirection="column" alignItems={["flex-start", "center"]} width={1} mb={[0, 50]}>
            <Heading as="h2" fontSize={["26px", null, "36px", null]} fontWeight="700" color={["green.dark", "white.100"]}>
              Získejte výhodné energie od&nbsp;MND
            </Heading>
          </Flex>
          <ContactForm
            enableCallbackTime={false}
            enableEmail={false}
            type="PAGE_HP"
            analytics={{
              text: "Ozveme se vám",
              type: "Lead",
              section: "LandingPage",
            }}
            commodity={getCommodityFromHash(typeof document !== "undefined" ? document.location.hash : "")}
          />
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          flexDirection={"column"}
          alignItems={["center", null, "stretch"]}
          justifyContent={"space-between"}
          maxWidth={755}
          pt={[40, null, 64]}
          pb={[45, null, 72]}
        >
          <Heading
            as="h3"
            fontSize={[5, null, 6]}
            fontWeight="700"
            color={"exploratoryGreen"}
            mb={["20px", null, "40px"]}
            dangerouslySetInnerHTML={{ __html: contentData.FAQSection.title }}
          />
          <Flex flexDirection={"column"}>
            {contentData.FAQSection.FAQs.map(faq => (
              <FAQItem question={faq.question} answer={faq.answer} />
            ))}
          </Flex>
        </Container>
      </Wrapper>
    </LayoutMain>
  );
};

export default PrepisEnergii;
